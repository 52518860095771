import { createContext, useContext, createElement } from "react";

const Context = createContext<BackgroundVariant>("light");

type BackgroundVariant = "contrast" | "light";

const backgroundTypeKey = "background" as const;
export interface HeaderBackgroundVariant {
  [backgroundTypeKey]: "contrast" | "light";
}
export const BackgroundProvider = Context.Provider;

export const shouldForwardProp = (propName: string): boolean =>
  propName !== backgroundTypeKey;

export const withBackground =
  <T>(Component: React.FC<T & HeaderBackgroundVariant>): React.FC<T> =>
  (props) =>
    createElement(Component, {
      ...props,
      [backgroundTypeKey]: useContext(Context),
    });
