import { Box, styled, useTheme } from "@mui/material";

import {
  type HeaderBackgroundVariant,
  withBackground,
  shouldForwardProp,
} from "./background";

const _SVGColored = styled("svg", {
  shouldForwardProp,
})<HeaderBackgroundVariant>`
  fill: ${({ background, theme }) =>
    background === "contrast"
      ? theme.palette.common.white
      : theme.palette.navy.main};
`;

const SVGColored = withBackground(_SVGColored);
export const KeyText: React.FC = () => (
  <SVGColored height="45px" viewBox="130 115 1200 380" width="179.58px">
    <g
      transform="translate(0.000000,623.000000) scale(0.100000,-0.100000)"
      fill="#0466C8"
    >
      <path d="M1415 4794 c-87 -9 -234 -38 -330 -65 -97 -28 -107 -29 -330 -29 -201 0 -235 -3 -270 -19 -77 -35 -137 -135 -155 -259 -15 -103 -12 -425 4 -503 25 -116 89 -212 159 -238 17 -7 127 -11 279 -11 l251 0 36 -38 c20 -21 128 -150 240 -287 273 -334 323 -389 374 -414 54 -26 121 -27 192 -2 l55 19 0 -83 c0 -75 -2 -85 -22 -99 -12 -9 -29 -16 -38 -16 -8 0 -42 -13 -75 -28 -77 -37 -155 -121 -186 -200 -31 -79 -31 -195 0 -274 26 -66 97 -151 150 -178 l37 -18 28 50 c38 68 76 90 144 85 62 -4 106 -39 124 -99 6 -21 17 -38 23 -38 18 0 86 52 122 94 161 182 99 473 -122 577 -33 16 -68 29 -78 29 -10 0 -27 5 -38 10 -17 9 -19 22 -19 100 0 50 2 90 4 90 2 0 25 -10 51 -21 97 -43 195 -7 250 92 l30 54 3 413 c2 235 7 412 12 412 9 0 79 -52 225 -167 83 -65 163 -103 218 -103 128 0 228 108 214 229 -10 83 -38 123 -145 208 -53 42 -127 101 -164 130 -38 30 -68 58 -68 64 0 6 86 9 243 8 271 -2 303 3 367 59 52 44 70 80 70 137 0 60 -19 103 -63 142 -70 61 -89 63 -618 63 l-477 0 -102 41 c-56 23 -143 50 -194 60 -88 19 -325 32 -411 23z m387 -770 c54 -38 78 -72 89 -125 9 -48 12 -509 4 -509 -19 0 -364 430 -374 466 -5 18 -6 49 -1 68 27 122 177 175 282 100z m198 -1419 c88 -45 54 -185 -45 -185 -97 0 -133 138 -47 184 35 19 56 20 92 1z" />
      <path d="M1900 2147 c-14 -7 -33 -28 -42 -47 -16 -32 -18 -75 -18 -450 0 -310 3 -421 13 -439 6 -14 27 -34 45 -44 29 -17 39 -18 71 -8 79 23 76 0 79 481 2 269 -1 437 -7 454 -12 29 -65 66 -96 66 -11 0 -31 -6 -45 -13z" />
    </g>
    <g transform="translate(0.000000,623.000000) scale(0.100000,-0.100000)">
      <path d="M9730 3270 l0 -930 195 0 195 0 0 930 0 930 -195 0 -195 0 0 -930z" />
      <path d="M3710 3195 l0 -855 620 0 620 0 0 195 0 195 -405 0 -405 0 0 660 0 660 -215 0 -215 0 0 -855z" />
      <path d="M7245 3658 c-294 -28 -513 -213 -585 -492 -28 -111 -29 -283 -1 -394 30 -120 72 -194 166 -288 123 -125 264 -184 435 -184 167 0 290 64 382 200 17 24 17 23 17 -67 l1 -93 181 0 180 0 -3 403 c-3 390 -4 404 -26 466 -34 95 -58 140 -105 202 -136 182 -374 274 -642 247z m222 -395 c92 -43 149 -130 160 -245 12 -136 -42 -253 -146 -311 -74 -42 -180 -49 -263 -17 -185 69 -244 356 -105 508 93 100 227 125 354 65z" />
      <path d="M5620 3644 c-36 -8 -107 -34 -158 -59 -197 -98 -323 -280 -353 -515 -43 -336 140 -631 456 -737 73 -25 94 -27 235 -27 141 0 162 2 235 27 178 59 311 173 385 328 16 35 30 70 30 77 0 9 -46 12 -198 12 -190 0 -200 -1 -218 -21 -10 -12 -41 -33 -69 -47 -45 -24 -61 -26 -155 -27 -95 0 -110 3 -155 27 -68 35 -108 83 -133 156 l-11 32 491 0 490 0 -5 123 c-9 214 -60 342 -191 473 -137 137 -286 194 -501 193 -60 -1 -139 -7 -175 -15z m325 -362 c50 -25 91 -66 116 -114 10 -20 19 -40 19 -43 0 -9 -548 -6 -554 4 -9 13 49 96 88 126 55 42 109 55 206 51 61 -2 97 -9 125 -24z" />
      <path d="M8290 3460 l0 -170 300 0 c170 0 300 -4 300 -9 0 -5 -26 -33 -59 -62 -32 -30 -176 -166 -319 -304 l-261 -250 0 -162 -1 -163 598 0 597 0 -2 167 -2 168 -330 5 -330 5 260 250 c143 138 287 275 319 305 l60 56 0 167 0 167 -565 0 -565 0 0 -170z" />
      <path d="M10430 3248 c0 -287 4 -399 14 -448 64 -302 259 -474 536 -474 124 0 190 25 269 100 30 30 57 54 59 54 3 0 3 -49 0 -109 -7 -144 -33 -206 -104 -247 -45 -26 -58 -29 -144 -29 -83 0 -101 3 -140 25 -25 14 -54 40 -65 57 l-20 32 -197 1 c-109 0 -198 -4 -198 -8 0 -29 50 -148 86 -204 67 -105 164 -172 314 -220 65 -20 96 -23 225 -23 129 0 161 3 225 23 212 66 333 191 385 398 l25 97 0 678 0 679 -194 0 -195 0 -3 -372 c-3 -369 -3 -374 -27 -426 -45 -100 -142 -152 -253 -138 -86 12 -145 55 -182 135 -20 44 -21 63 -24 424 l-3 377 -195 0 -194 0 0 -382z" />
    </g>
  </SVGColored>
);

export const HeaderLogo: React.FC = () => (
  <Box display={{ md: "initial", xs: "none" }}>
    <KeyText />
  </Box>
);
export const Key: React.FC = () => (
  <svg
    height="45px"
    width="32px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="50 300 400 10"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#0466C8"
      stroke="none"
    >
      <path d="M2080 4810 c-136 -13 -275 -39 -399 -77 l-116 -36 -298 -3 c-272 -4 -301 -6 -336 -24 -72 -36 -146 -154 -170 -268 -17 -80 -14 -613 3 -679 22 -82 61 -158 106 -207 66 -72 91 -76 436 -76 264 0 294 -2 312 -17 23 -21 56 -60 331 -397 412 -503 410 -501 551 -501 66 0 96 5 133 22 l48 22 -3 -111 -3 -112 -84 -29 c-46 -15 -102 -41 -125 -56 -146 -101 -226 -286 -197 -451 22 -124 97 -243 190 -302 25 -15 50 -28 55 -28 6 0 24 25 40 56 50 95 134 133 221 100 53 -21 90 -62 105 -116 6 -22 15 -42 21 -45 5 -4 32 8 60 25 190 122 259 380 158 585 -54 111 -179 213 -287 236 -88 19 -92 26 -92 139 0 53 2 99 5 102 3 3 31 -5 63 -18 75 -31 135 -31 199 1 58 29 96 74 127 149 20 49 21 69 24 544 2 270 6 492 10 492 4 0 86 -62 182 -137 207 -161 267 -193 360 -193 184 0 299 156 246 334 -22 72 -53 105 -248 258 -99 79 -186 148 -194 154 -8 6 -14 14 -14 18 0 3 137 6 305 6 277 0 309 2 355 19 117 44 187 138 177 236 -10 102 -69 175 -170 211 -48 17 -100 19 -662 24 l-610 5 -44 24 c-119 63 -278 104 -472 121 -126 11 -173 11 -299 0z m416 -909 c55 -25 111 -76 135 -123 17 -34 21 -67 26 -231 3 -106 3 -249 -2 -319 l-7 -127 -49 56 c-126 144 -381 466 -394 497 -21 50 -19 85 10 143 52 103 179 150 281 104z m302 -1766 c44 -36 58 -77 43 -125 -41 -137 -231 -104 -231 40 0 34 6 48 34 76 29 29 41 34 79 34 34 0 53 -6 75 -25z" />
      <path d="M2649 1585 c-59 -32 -59 -35 -59 -592 0 -572 -1 -560 73 -597 48 -23 96 -15 140 24 l32 28 3 529 c3 581 5 569 -58 606 -34 21 -96 21 -131 2z" />
    </g>
  </svg>
);

export const KeyHeaderLogo: React.FC = () => (
  <Box display={{ md: "none" }} height="32px">
    <Key />
  </Box>
);

export const BurgerMenuLogo: React.FC = () => (
  <svg
    fill={useTheme().palette.navy.main}
    height={useTheme().spacing(4)}
    viewBox="0 0 1100 196"
  >
    <g
      transform="translate(0.000000,623.000000) scale(0.100000,-0.100000)" //peut-être à enlever
      fill="#0466C8"
    >
      <path d="M1415 4794 c-87 -9 -234 -38 -330 -65 -97 -28 -107 -29 -330 -29 -201 0 -235 -3 -270 -19 -77 -35 -137 -135 -155 -259 -15 -103 -12 -425 4 -503 25 -116 89 -212 159 -238 17 -7 127 -11 279 -11 l251 0 36 -38 c20 -21 128 -150 240 -287 273 -334 323 -389 374 -414 54 -26 121 -27 192 -2 l55 19 0 -83 c0 -75 -2 -85 -22 -99 -12 -9 -29 -16 -38 -16 -8 0 -42 -13 -75 -28 -77 -37 -155 -121 -186 -200 -31 -79 -31 -195 0 -274 26 -66 97 -151 150 -178 l37 -18 28 50 c38 68 76 90 144 85 62 -4 106 -39 124 -99 6 -21 17 -38 23 -38 18 0 86 52 122 94 161 182 99 473 -122 577 -33 16 -68 29 -78 29 -10 0 -27 5 -38 10 -17 9 -19 22 -19 100 0 50 2 90 4 90 2 0 25 -10 51 -21 97 -43 195 -7 250 92 l30 54 3 413 c2 235 7 412 12 412 9 0 79 -52 225 -167 83 -65 163 -103 218 -103 128 0 228 108 214 229 -10 83 -38 123 -145 208 -53 42 -127 101 -164 130 -38 30 -68 58 -68 64 0 6 86 9 243 8 271 -2 303 3 367 59 52 44 70 80 70 137 0 60 -19 103 -63 142 -70 61 -89 63 -618 63 l-477 0 -102 41 c-56 23 -143 50 -194 60 -88 19 -325 32 -411 23z m387 -770 c54 -38 78 -72 89 -125 9 -48 12 -509 4 -509 -19 0 -364 430 -374 466 -5 18 -6 49 -1 68 27 122 177 175 282 100z m198 -1419 c88 -45 54 -185 -45 -185 -97 0 -133 138 -47 184 35 19 56 20 92 1z" />
      <path d="M1900 2147 c-14 -7 -33 -28 -42 -47 -16 -32 -18 -75 -18 -450 0 -310 3 -421 13 -439 6 -14 27 -34 45 -44 29 -17 39 -18 71 -8 79 23 76 0 79 481 2 269 -1 437 -7 454 -12 29 -65 66 -96 66 -11 0 -31 -6 -45 -13z" />
    </g>
    <g transform="translate(0.000000,623.000000) scale(0.100000,-0.100000)">
      <path d="M9730 3270 l0 -930 195 0 195 0 0 930 0 930 -195 0 -195 0 0 -930z" />
      <path d="M3710 3195 l0 -855 620 0 620 0 0 195 0 195 -405 0 -405 0 0 660 0 660 -215 0 -215 0 0 -855z" />
      <path d="M7245 3658 c-294 -28 -513 -213 -585 -492 -28 -111 -29 -283 -1 -394 30 -120 72 -194 166 -288 123 -125 264 -184 435 -184 167 0 290 64 382 200 17 24 17 23 17 -67 l1 -93 181 0 180 0 -3 403 c-3 390 -4 404 -26 466 -34 95 -58 140 -105 202 -136 182 -374 274 -642 247z m222 -395 c92 -43 149 -130 160 -245 12 -136 -42 -253 -146 -311 -74 -42 -180 -49 -263 -17 -185 69 -244 356 -105 508 93 100 227 125 354 65z" />
      <path d="M5620 3644 c-36 -8 -107 -34 -158 -59 -197 -98 -323 -280 -353 -515 -43 -336 140 -631 456 -737 73 -25 94 -27 235 -27 141 0 162 2 235 27 178 59 311 173 385 328 16 35 30 70 30 77 0 9 -46 12 -198 12 -190 0 -200 -1 -218 -21 -10 -12 -41 -33 -69 -47 -45 -24 -61 -26 -155 -27 -95 0 -110 3 -155 27 -68 35 -108 83 -133 156 l-11 32 491 0 490 0 -5 123 c-9 214 -60 342 -191 473 -137 137 -286 194 -501 193 -60 -1 -139 -7 -175 -15z m325 -362 c50 -25 91 -66 116 -114 10 -20 19 -40 19 -43 0 -9 -548 -6 -554 4 -9 13 49 96 88 126 55 42 109 55 206 51 61 -2 97 -9 125 -24z" />
      <path d="M8290 3460 l0 -170 300 0 c170 0 300 -4 300 -9 0 -5 -26 -33 -59 -62 -32 -30 -176 -166 -319 -304 l-261 -250 0 -162 -1 -163 598 0 597 0 -2 167 -2 168 -330 5 -330 5 260 250 c143 138 287 275 319 305 l60 56 0 167 0 167 -565 0 -565 0 0 -170z" />
      <path d="M10430 3248 c0 -287 4 -399 14 -448 64 -302 259 -474 536 -474 124 0 190 25 269 100 30 30 57 54 59 54 3 0 3 -49 0 -109 -7 -144 -33 -206 -104 -247 -45 -26 -58 -29 -144 -29 -83 0 -101 3 -140 25 -25 14 -54 40 -65 57 l-20 32 -197 1 c-109 0 -198 -4 -198 -8 0 -29 50 -148 86 -204 67 -105 164 -172 314 -220 65 -20 96 -23 225 -23 129 0 161 3 225 23 212 66 333 191 385 398 l25 97 0 678 0 679 -194 0 -195 0 -3 -372 c-3 -369 -3 -374 -27 -426 -45 -100 -142 -152 -253 -138 -86 12 -145 55 -182 135 -20 44 -21 63 -24 424 l-3 377 -195 0 -194 0 0 -382z" />
    </g>
  </svg>
);
/*
export const HouseTextBlog: React.FC = () => (
  <svg
    width="auto"
    height="auto"
    viewBox="0 0 165 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M9.78568 21.2966C10.3332 20.6825 11.2384 20.6626 11.8081 21.2528C13.5023 23.0077 15.4123 23.8463 17.5914 23.8463C19.7705 23.8463 21.6804 23.0077 23.3747 21.2528C23.9443 20.6626 24.8495 20.6825 25.3971 21.2966C25.9446 21.9106 25.9262 22.8864 25.3565 23.4767C23.159 25.7522 20.5503 26.9287 17.5914 26.9287C14.6324 26.9287 12.0238 25.7522 9.82624 23.4767C9.25658 22.8864 9.23814 21.9106 9.78568 21.2966Z"
        fill={useTheme().palette.coral.main}
      />
      <path
        d="M19.282 3.74815C18.3085 2.86378 16.8872 2.86378 15.9137 3.74815L3.79039 14.766C3.20045 15.3006 2.86123 16.0896 2.86123 16.9203V30.7225C2.86123 32.2786 4.0319 33.5406 5.47542 33.5406H29.724C31.1675 33.5406 32.3382 32.2786 32.3382 30.7225V16.9203C32.3382 16.0896 31.9989 15.3006 31.409 14.766L19.282 3.74815ZM14.0702 1.38916C16.1092 -0.463053 19.0865 -0.463053 21.1255 1.38916L33.2507 12.407C34.4841 13.5279 35.1975 15.1794 35.1975 16.9203V30.7225C35.1975 33.9817 32.7474 36.6249 29.724 36.6249H5.47358C2.45011 36.6249 0 33.9817 0 30.7225V16.9203C0 15.1794 0.71162 13.5279 1.94682 12.407L14.0702 1.38916Z"
        fill={useTheme().palette.coral.main}
      />
      <path
        d="M48.7073 28.7869C51.8008 28.7869 53.8804 26.7737 53.8804 24.2716C53.8804 18.3473 46.4932 20.3903 46.4932 17.8306C46.4932 16.9959 47.214 16.507 48.2796 16.507C49.3452 16.507 50.5472 17.2264 50.9196 18.6057L53.6389 17.3695C53.0526 15.2709 50.8385 13.832 48.2261 13.832C45.293 13.832 43.4531 15.6147 43.4531 17.8862C43.4531 23.3793 50.7869 21.5966 50.7869 24.3272C50.7869 25.2474 49.961 25.9668 48.7073 25.9668C47.0278 25.9668 45.988 24.7585 45.588 23.2918L42.8411 24.5001C43.5084 26.6286 45.4553 28.7849 48.7073 28.7849V28.7869Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M55.7737 28.5563H59.1345V21.1951C59.1345 18.6354 60.1743 16.9104 62.0142 16.9104C63.5609 16.9104 64.4145 18.0035 64.4145 19.7285V28.5563H67.7754V21.1653C67.7754 18.6056 68.8151 16.9104 70.6292 16.9104C72.2018 16.9104 73.083 18.0035 73.083 19.7285V28.5563H76.4438V18.7229C76.4438 15.877 74.5505 13.834 71.6432 13.834C69.5102 13.834 67.9099 15.0999 67.1891 16.5666C66.5752 14.8118 65.1354 13.834 63.0023 13.834C61.1348 13.834 59.883 14.8396 59.1364 16.2784V14.0645H55.7755V28.5563H55.7737Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M92.0533 14.0645H88.6925V15.6743C87.92 14.5812 86.4783 13.834 84.7988 13.834C80.8259 13.834 78.4514 16.8826 78.4514 21.3104C78.4514 25.7382 80.8241 28.7868 84.8523 28.7868C86.5318 28.7868 88.0251 28.0396 88.6925 26.8889V28.5563H92.0533V14.0645ZM85.3335 25.6528C83.2005 25.6528 81.7864 23.9278 81.7864 21.3104C81.7864 18.6931 83.2005 16.968 85.3335 16.968C87.4665 16.968 88.9063 18.6931 88.9063 21.3104C88.9063 23.9278 87.4923 25.6528 85.3335 25.6528Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M102.34 17.4291V13.9214C102.34 13.9214 101.994 13.8359 101.54 13.8359C99.9143 13.8359 98.3676 15.0442 97.7537 16.7116V14.0665H94.3928V28.5582H97.7537V23.2381C97.7537 18.9255 99.3262 17.3138 101.194 17.3138C101.727 17.3138 102.34 17.4291 102.34 17.4291Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M111.099 28.4132L110.86 25.2494C110.38 25.5077 109.792 25.5952 109.287 25.5952C108.247 25.5952 107.554 24.8479 107.554 23.4667V17.1111H110.941V14.0625H107.554V9.80762H104.193V23.8105C104.193 27.2029 106.219 28.7849 108.941 28.7849C109.713 28.7849 110.434 28.6696 111.101 28.4112L111.099 28.4132Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M117.401 26.9007V28.5602H114.056V9.75781H117.401V15.7378C118.171 14.6507 119.604 13.9054 121.278 13.9054C125.234 13.9054 127.596 16.9381 127.596 21.3461C127.596 25.754 125.232 28.7867 121.224 28.7867C119.578 28.7867 118.064 28.0434 117.401 26.8987V26.9007ZM124.277 21.3481C124.277 18.7446 122.871 17.0276 120.747 17.0276C118.623 17.0276 117.189 18.7446 117.189 21.3481C117.189 23.9515 118.595 25.6705 120.747 25.6705C122.898 25.6705 124.277 23.9535 124.277 21.3481Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M130.116 9.75781H133.46V28.5602H130.116V9.75781Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M135.982 21.3481C135.982 17.0554 138.902 13.9075 142.964 13.9075C147.025 13.9075 149.945 17.0554 149.945 21.3481C149.945 25.6408 147.025 28.7888 142.964 28.7888C138.902 28.7888 135.982 25.6408 135.982 21.3481ZM146.575 21.3481C146.575 18.7725 145.115 17.0276 142.964 17.0276C140.812 17.0276 139.354 18.7725 139.354 21.3481C139.354 23.9237 140.788 25.6706 142.964 25.6706C145.139 25.6706 146.575 23.9257 146.575 21.3481Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M165 14.136V27.6441C165 31.4498 162.345 33.9976 158.363 33.9976C155.231 33.9976 152.709 32.1951 152.257 29.5042L155.364 28.9597C155.522 30.3051 156.798 31.106 158.363 31.106C160.38 31.106 161.707 29.8759 161.707 28.0157V26.4417C161.044 27.4712 159.583 28.131 157.991 28.131C154.062 28.131 151.752 25.2692 151.752 21.0341C151.752 16.7991 154.062 13.9075 157.911 13.9075C159.558 13.9075 160.938 14.5951 161.681 15.5967V14.138H165V14.136ZM161.893 21.0321C161.893 18.5996 160.54 17.0256 158.468 17.0256C156.396 17.0256 155.07 18.5996 155.07 21.0321C155.07 23.4647 156.424 25.0108 158.468 25.0108C160.513 25.0108 161.893 23.4368 161.893 21.0321Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M44.2531 36.7065H46.5741C47.7116 36.7065 48.3421 37.2928 48.3421 38.2626C48.3421 39.1391 47.7393 39.7452 47.1954 39.9718C47.7688 40.1884 48.1504 40.6832 48.1504 41.506C48.1504 42.927 47.2323 43.9167 45.6855 43.9167H43.1912L44.2512 36.7065H44.2531ZM45.6394 43.2787C46.8248 43.2787 47.4646 42.6607 47.4646 41.5895C47.4646 40.8164 46.9871 40.3951 46.0598 40.3951H44.3489L43.9286 43.2787H45.6394ZM46.1261 39.7551C47.1014 39.7551 47.6747 39.2702 47.6747 38.3441C47.6747 37.6744 47.2452 37.3445 46.4893 37.3445H44.7988L44.4448 39.7551H46.1261Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M51.4117 40.8899L49.6345 36.7085H50.3314L51.8026 40.2619L54.2969 36.7085H55.062L52.0514 40.8899L51.6016 43.9186H50.9619L51.4117 40.8899Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M58.1001 41.816L58.749 41.6093C58.7398 42.7123 59.551 43.3602 60.5262 43.3602C61.6158 43.3602 62.3127 42.7421 62.3127 41.8458C62.3127 40.0234 58.8633 40.7548 58.8633 38.5707C58.8633 37.4577 59.8183 36.623 61.1567 36.623C62.4952 36.623 63.3746 37.3345 63.3746 38.3958L62.7441 38.6025C62.7256 37.7479 62.0841 37.263 61.1586 37.263C60.1262 37.263 59.5344 37.7876 59.5344 38.5607C59.5344 40.1466 63.004 39.3735 63.004 41.8259C63.004 43.072 61.9919 44.0001 60.5281 44.0001C59.1528 44.0001 58.1591 43.1455 58.1001 41.816Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M65.4393 36.7065H66.2321L67.694 42.9588L71.0106 36.7065H71.8421L70.782 43.9167H70.1331L71.0032 38.0242L67.8784 43.9167H67.2866L65.9002 38.0242L65.03 43.9167H64.3811L65.4412 36.7065H65.4393Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M77.0319 41.9511H73.8499L72.8377 43.9186H72.1777L75.9331 36.7085H76.4972L78.1306 43.9186H77.4614L77.0319 41.9511ZM76.8881 41.3132L76.0861 37.6247L74.1854 41.3132H76.8899H76.8881Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M83.0824 40.4666L84.5923 43.9167H83.8751L82.3744 40.558H80.6636L80.1658 43.9167H79.5261L80.5862 36.7065H82.9072C84.0355 36.7065 84.7232 37.3564 84.7232 38.3541C84.7232 39.4153 84.0632 40.2083 83.0787 40.4666H83.0824ZM80.7503 39.9201H82.3929C83.4161 39.9201 84.0466 39.3537 84.0466 38.4375C84.0466 37.7479 83.5875 37.3465 82.7763 37.3465H81.1337L80.7521 39.9221L80.7503 39.9201Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M88.2721 37.3464H86.0837L86.1796 36.7085H91.1868L91.0909 37.3464H88.9118L87.9458 43.9186H87.306L88.2721 37.3464Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M95.1708 40.4666L96.6806 43.9167H95.9635L94.4628 40.558H92.752L92.2542 43.9167H91.6145L92.6746 36.7065H94.9956C96.1239 36.7065 96.8115 37.3564 96.8115 38.3541C96.8115 39.4153 96.1515 40.2083 95.1671 40.4666H95.1708ZM92.8386 39.9201H94.4831C95.5063 39.9201 96.1368 39.3537 96.1368 38.4375C96.1368 37.7479 95.6777 37.3465 94.8666 37.3465H93.2239L92.8423 39.9221L92.8386 39.9201Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M99.1842 36.7066H103.264L103.168 37.3445H99.7281L99.3741 39.7552H102.193L102.097 40.3931H99.2782L98.8579 43.2768H102.403L102.307 43.9147H98.1223L99.1824 36.7046L99.1842 36.7066Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M105.109 36.7065H105.779L108.645 42.8355L109.543 36.7065H110.192L109.132 43.9167H108.454L105.596 37.8075L104.698 43.9167H104.049L105.109 36.7065Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M113.778 37.3464H111.59L111.685 36.7085H116.693L116.597 37.3464H114.418L113.452 43.9186H112.812L113.778 37.3464Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M118.184 36.7065H118.824L117.764 43.9167H117.124L118.184 36.7065Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M120.85 36.7065H121.519L124.386 42.8355L125.284 36.7065H125.933L124.873 43.9167H124.194L121.337 37.8075L120.439 43.9167H119.79L120.85 36.7065Z"
        fill={useTheme().palette.navy.main}
      />
      <path
        d="M127.072 40.9418C127.072 38.0979 129.021 36.6252 130.857 36.6252C132.271 36.6252 133.237 37.4897 133.303 38.8292L132.654 38.9842C132.606 37.9031 131.91 37.2632 130.763 37.2632C129.1 37.2632 127.763 38.5927 127.763 40.8881C127.763 42.484 128.604 43.3604 129.874 43.3604C131.25 43.3604 132.243 42.3508 132.464 40.908H129.942L130.038 40.2701H133.172L132.638 43.9168H132.064L132.247 42.6708C131.808 43.4836 130.814 44.0003 129.791 44.0003C128.329 44.0003 127.078 42.9709 127.078 40.9418H127.072Z"
        fill={useTheme().palette.navy.main}
      />
    </g>
  </svg>
  
);*/
